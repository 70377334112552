import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../layouts/layout';
import VideoCarousel from '../components/carousel';
import { contentHeight } from '../utils/config';

const content = css`
  min-height: ${contentHeight}vh;
`;

const Films = ({ data: { prismicFilms } }) => {
  const films = prismicFilms.data.body[0].items;
  // eslint-disable-next-line
  const [filmIndex, setFilmIndex] = useState(0);
  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h1>{prismicFilms.data.title.text}</h1>
        <Row>
          <Col md={8}>
            <VideoCarousel
              films={films}
              showCarouselItems
              onVideoChange={currentIndex => setFilmIndex(currentIndex)}
            />
          </Col>
          <Col md={4}>
            <h3>{films[filmIndex].film_title.text}</h3>
            <p>{films[filmIndex].film_description.text}</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Films;

export const pageQuery = graphql`
  query FlimsQuery {
    prismicFilms {
      data {
        title {
          text
        }
        body {
          id
          __typename
          ... on PrismicFilmsBodyFilmsGallery {
            items {
              film_title {
                text
              }
              thumbnail {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              film_description {
                text
              }
              video_id {
                text
              }
              video {
                title
                provider_name
                embed_url
              }
            }
          }
        }
      }
    }
  }
`;
